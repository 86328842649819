<template>
    <div>
        <div class="row p-3">
            <h1 class="offset-2 col-sm-6 text-center">F y C</h1> 
        </div>   
        <div class=" row">
            <div class="text-right col-3">
                <div class=" btn-group btn-block" role="group">
                    <button class="btn btn-warning " @click="obtener_solicitudes">Recargar</button>
                    <div class="btn-group" role="group">
                    <select v-model="solicitud_tipo" name="tipo_solicitud" id="tipo_solicitud" class="form-control">
                        <option value="a">Ver todos las solicitudes</option>
                        <option value="directo">Solicitudes Fondeo Directo</option>
                        <option value="cofinanciado">Solicitudes Cofinanciadas</option>
                        <option value="0|">Solicitudes Votación Abierta</option>
                        <option value="1|">Solicitudes Votación Cerrada</option>
                        <option value="1">Solicitudes Activas</option>
                        <option value="2">Solicitudes Aceptadas Fodeador</option>
                        <option value="3">Solicitudes Rechazadas Fodeador</option>
                        <option value="4">Solicitudes En Espera Formalización</option>
                        <option value="5">Solicitudes Seleccionas Formalización</option>
                        <option value="6">Solicitudes Rechazadas Formalización</option>
                    </select>
                    </div>
                </div>                 
            </div>    

            <div class="col-3 mb-3 offset-6">
                <div class="input-group mb-3">
                    <input v-model="buscar" type="text" class="form-control" placeholder="Solicitud ID">
                    <div class="input-group-append">
                        <button class="btn btn-primary">Buscar</button>
                    </div>
                </div>
            </div>    
        </div>    
        <div class="col-sm-12">
            <div v-if="solicitudes_paginadas.length == 0">
                <div class="offset-2 mt-5"> <h2>¡Esta solicitud no ha sido enviada a algún fondeador!</h2> </div>
            </div>
            <div v-else>
                <div class="row mt-4">
                    <ResumenSolicitud class="col-sm-12 mb-4" v-for="solicitud in solicitudes_paginadas" :key="solicitud.id" :solicitud="solicitud" @update="obtener_solicitudes"/>
                    <div v-if="paginas_mostrar.length > 1" class="row col-sm-12 mt-5">
                        <nav>
                            <ul class="pagination">
                                <li class="page-item"><a href="#" class="page-link" @click="pagina_anterior">Anterior</a></li>
                                <div v-for="num_pagina in paginas_mostrar" :key="num_pagina.id">
                                    <li :class="'page-item'+(num_pagina == pagina_actual ? ' pagina_actual' : '') "><a href="#" class="page-link" @click="pagina_actual = num_pagina"> {{ num_pagina }} </a></li>
                                </div>
                                <li class="page-item"><a href="#" class="page-link" @click="pagina_siguiente">Siguiente</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>    
  </div>
</template>

<script>
import api_cofinanciamiento from "@/apps/formalizacion/api/v2/cofinanciamiento";
import ResumenSolicitud from "./Fyc/ResumenSolicitud";

export default {
    components:{
        ResumenSolicitud
    },
    props:{
        solicitud:null,
    },
    data(){
        return{
            modal_enviar_solicitud_fondeador:false,
            solicitudes:[],
            pagina_actual:1,
            por_pagina:3,
            buscar:null,
            solicitud_tipo: 'a',
        }
    },
    beforeMount(){
        this.obtener_solicitudes();
    },    
    methods:{
        async obtener_solicitudes(){
            try {
                this.$log.info('solicitud formalizacion ', this.solicitud)
                this.solicitudes = (await api_cofinanciamiento.obtener_solicitudes(this.solicitud.solicitud)).data;
                // this.$log.info('solicitudes fyc ', this.solicitudes);                
            } catch (error) {
                this.$log.info('error', error);
                this.$helper.showAxiosError(error,'Error');                
            }
        },
        pagina_anterior(){
            if(this.pagina_actual > 1)
                this.pagina_actual -=1;
        },
        pagina_siguiente(){
            if(this.pagina_actual < this.$paginator.total_pages(this.solicitudes_visibles, this.por_pagina))
                this.pagina_actual += 1;
        },

        cerrar_modal_enviar_solicitud_fondeador(){
            this.modal_enviar_solicitud_fondeador = false;
            // this.$emit('close');
        }        
    },
    computed:{
        solicitudes_visibles(){
            let solicitudes = [];
            if(this.solicitud_tipo !== 'a')
                this.solicitudes.forEach(solicitud => {
                    if(solicitud.tipo_fondeo == this.solicitud_tipo)
                        solicitudes.push(solicitud);

                    if(this.solicitud_tipo.includes('|'))
                        if(solicitud.estatus_votacion == this.solicitud_tipo.replace('|', ''))
                            solicitudes.push(solicitud);
                    
                    if(solicitud.estatus == this.solicitud_tipo)
                        solicitudes.push(solicitud);
                });
            else
                solicitudes = this.solicitudes;

            if(this.buscar && this.buscar !== ''){
                let busqueda = [];
                this.solicitudes.forEach(solicitud => {
                    if(Object.values(solicitud.solicitud).toString().indexOf(this.buscar) != -1)
                        busqueda.push(solicitud);
                });
                solicitudes = busqueda;
            }
            return solicitudes;
        },
        solicitudes_paginadas(){
            return this.$paginator.paginate_data(this.solicitudes_visibles, this.pagina_actual, this.por_pagina, 'desc');
        },
        paginas_mostrar(){
            return this.$paginator.paginate_navigation(this.solicitudes_visibles, this.pagina_actual, this.por_pagina, 3);
        }
    },
    watch:{
        solicitud_tipo(){
            this.pagina_actual = 1;
        },
        solicitud(){
            this.obtener_solicitudes();
        }
    }
}
</script>

<style lang="scss" scope>
  .pagina_actual {
    a {
      background-color: #C4E5C5;
    }
  }
</style>